<template>
    <div ref="videoWrapper" class="video-thumbnail">
        <template v-if="!loaded">
            <img :src="thumbnailUrl" alt="Video Thumbnail" @click="loadVideo">
            <div class="play-button" @click="loadVideo">▶</div>
        </template>
        <template v-else>
            <iframe
                :src="iframeUrl"
                frameborder="0"
                allow="autoplay; fullscreen"
                allowfullscreen
                width="100%"
                height="100%"
            >
            </iframe>
        </template>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';

// Props
const props = defineProps({
    src: {
        type: String,
        required: true
    }
});

// State
const loaded = ref(false);
const videoWrapper = ref(null);
const videoId = ref('');

// Extract Video ID
const extractVideoId = (url) => {
    const regExp = /vimeo\.com\/(?:.*#|.*\/video\/)?([0-9]+)/i;
    const match = url.match(regExp);

    if (match && match[1]) {
        videoId.value = match[1];
    } else {
        console.error('Invalid Vimeo URL');
    }
};

// Computed Properties
const thumbnailUrl = computed(() => {
    return `https://vumbnail.com/${videoId.value}.jpg`;
});

const iframeUrl = computed(() => {
    return `https://player.vimeo.com/video/${videoId.value}?autoplay=1`;
});

// Methods
const loadVideo = () => {
    loaded.value = true;
};

// Life-cycle Hooks
onMounted(() => {
    extractVideoId(props.src);
});
</script>

<style>
.video-thumbnail {
    position: relative;
    cursor: pointer;
}

.video-thumbnail img {
    width: 100%;
    height: auto;
}

.video-thumbnail .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 50px;
    color: white;
    pointer-events: none;
}
</style>
